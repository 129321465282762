/* eslint-disable no-await-in-loop */
import React, { useMemo, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import ShadowBlock from '../../../atoms/ShadowBlock';
import Form from '../../../atoms/Form';
import {
  BillingAuditColumns,
  BillingAuditFormBoxes,
  BILLING_STATUSES,
} from './BillingAudit.constants';
import MButton from '../../../MUI/Button/MButton';
import Field from '../../../atoms/Field';

import {
  createBillingAuditReport,
  getBillingAuditReport,
  getBillingAuditReportAsCsv,
} from '../../../../store/slices/reportsSlice';
import timeoutPromise from '../../../../utils/timeoutPromise';
import { ReactComponent as ExportIcon } from '../../../../assets/icons/document-download-blue.svg';

import Table from '../../../atoms/Table/Table';
import notificationUtils from '../../../../utils/notificationUtils';
import DateOrTimePicker from '../../../atoms/DateOrTimePicker';
import { ReactComponent as Calender } from '../../../../assets/icons/Calender.svg';
import { palette } from '../../../../theme/default';
import ProgressModal from '../ProductivityByAttorney/ProgressModal';
import { openOrDownloadBinary } from '../../../../utils/binaryHelpers';
import CheckboxSelect from '../../../atoms/CheckboxSelect';

const BillingAudit = () => {
  const [isLoading, setLoading] = useState(false);
  const [lastReportId, setLastReportId] = useState(null);
  const [tableLabel, setTableLabel] = useState('');
  const [reportData, setReportData] = useState(null);

  const dispatch = useDispatch();

  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const getOptions = (name) => {
    switch (name) {
      case 'status':
        return BILLING_STATUSES;
      default:
        return [];
    }
  };

  const handleSubmit = async (values) => {
    const data = {
      startDate: moment(values.startDate).format('YYYY-MM-DD'),
      endDate: moment(values.endDate).format('YYYY-MM-DD'),
    };

    if (values.status?.length === 1) {
      data.status = values.status[0].value;
    }

    setLoading(true);

    try {
      const { reportId } = await dispatch(createBillingAuditReport(data)).unwrap();
      let done = false;

      while (!done) {
        const {
          reportStatus: { code, message },
          items = [],
        } = await dispatch(getBillingAuditReport(reportId)).unwrap();
        if (code === 'Completed') {
          done = true;
          const { startDate, endDate, status, items: reportItems } = items?.[0] || {};
          setReportData(reportItems);
          setTableLabel(
            `Report from ${startDate} to ${endDate} ${status ? `with status ${status}` : ''}`,
          );
          setLastReportId(reportId);
        } else if (code === 'Error') {
          notificationUtils.error(message);
          done = true;
        } else {
          await timeoutPromise(5000);
        }
      }
    } catch (e) {
      notificationUtils.error(e.message || 'Something went wrong');
    }
    setLoading(false);
  };

  const onExport = async () => {
    setLoading(true);

    try {
      const data = await dispatch(getBillingAuditReportAsCsv(lastReportId)).unwrap();

      openOrDownloadBinary({
        contentType: data.contentType,
        content: data.content,
        fileName: data.originalFileName,
      });
    } catch (e) {
      notificationUtils.error(e.message || 'Something went wrong');
    }

    setLoading(false);
  };

  const rowsAdapter = useMemo(() => {
    if (!reportData) {
      return [];
    }

    return reportData;
  }, [reportData]);

  return (
    <Stack pb={20}>
      <ProgressModal isOpen={isLoading} />
      <ShadowBlock>
        <Form form={form} onSubmit={handleSubmit}>
          <Stack
            direction="row"
            alignItems="center"
            columnGap={3}
            rowGap={3}
            flexWrap="wrap"
            py={1}
          >
            {BillingAuditFormBoxes.map((item) => {
              if (item.isDatePicker) {
                return (
                  <Field
                    name={item.name}
                    key={item.name}
                    isMandatory={item.isMandatory}
                    render={({ field, onCustomChange, error }) => (
                      <DateOrTimePicker
                        name={item.name}
                        label={item.label}
                        isDate
                        error={error}
                        Svg={Calender}
                        selected={field.value}
                        isMandatory={item.isMandatory}
                        width={item.width}
                        height={item.height}
                        placeholder={item.placeholder}
                        setSelectedTimeOrDate={onCustomChange(field.onChange)}
                      />
                    )}
                  />
                );
              }

              if (item.isCheckboxSelect) {
                return (
                  <Field
                    key={item.name}
                    isMandatory={item.isMandatory}
                    name={item.name}
                    render={({ field, onCustomChange, error }) => (
                      <CheckboxSelect
                        label={item.label}
                        value={field.value}
                        width={item.width}
                        error={error}
                        isMandatory={item.isMandatory}
                        isDisabled={item.isDisabled}
                        placeholder={item.placeholder}
                        onChange={onCustomChange(field.onChange)}
                        options={getOptions(item.name)}
                      />
                    )}
                  />
                );
              }

              return null;
            })}
          </Stack>
          <Stack
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              mt: '36px',
            }}
          >
            <MButton type="submit" disabled={isLoading || !form.formState.isValid}>
              Run Report
            </MButton>
          </Stack>
        </Form>
      </ShadowBlock>

      {Boolean(reportData) && (
        <Stack mt={4}>
          <ShadowBlock>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="h5" fontWeight={600}>
                Billing Audit Report
              </Typography>
              <MButton
                variant="bordered"
                startIcon={<ExportIcon />}
                onClick={onExport}
                disabled={reportData.length === 0}
              >
                Export CSV
              </MButton>
            </Box>
            <Stack rowGap={2}>
              <Typography variant="bodyXS" color={palette.text.secondary}>
                {tableLabel}
              </Typography>
              <Table columns={BillingAuditColumns} rows={rowsAdapter} />
            </Stack>
          </ShadowBlock>
        </Stack>
      )}
    </Stack>
  );
};

export default BillingAudit;
