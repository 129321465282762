import { ReactComponent as DollarInCircle } from '../../../../assets/icons/DollarInCircle.svg';
import { ReactComponent as Clock } from '../../../../assets/icons/Clock.svg';
import TypeCell from './TableCells/TypeCell';
import TextEllipsis from './TableCells/TextEllipsis';
import TextCell from './TableCells/TextCell';
import { StatusCell } from './TableCells/StatusCell';
import { DateCell } from './TableCells/DateCell';
import QuantityCell from './TableCells/QuantityCell';
import NonBillableCell from './TableCells/NonBillableCell';
import BillableCell from './TableCells/BillableCell';

export const TABLE_ROWS_COUNT = 20;

export const TypeRadio = [
  { label: 'All', value: 'All' },
  { label: 'Time', value: 'Time', buttonWidth: '179px', icon: <Clock /> },
  { label: 'Expense', value: 'Expense', buttonWidth: '179px', icon: <DollarInCircle /> },
];

export const TABLE_COLUMNS = [
  {
    Header: 'Type',
    accessor: 'type',
    width: 4,
    Cell: TypeCell,
  },
  {
    Header: 'Qty',
    width: 10,
    accessor: 'quantity',
    Cell: QuantityCell,
  },
  {
    Header: 'Description',
    width: 20,
    accessor: 'description',
    Cell: TextEllipsis,
  },
  {
    width: 10,
    Header: 'Rate ($)',
    accessor: 'rate',
    Cell: TextCell,
  },
  {
    Header: 'Non-billable ($)',
    accessor: 'nonBillable',
    width: 10,
    Cell: NonBillableCell,
  },
  {
    Header: 'Billable ($)',
    accessor: 'billable',
    width: 10,
    Cell: BillableCell,
  },
  {
    Header: 'Date ',
    accessor: 'date',
    width: 10,
    Cell: DateCell,
  },
  {
    Header: 'User',
    accessor: 'user',
    width: 15,
    Cell: TextCell,
  },
  {
    Header: 'Category',
    accessor: 'category',
    width: 15,
    Cell: TextCell,
  },
  {
    Header: 'Invoice Status',
    accessor: 'invoiceStatus',
    width: 10,
    Cell: StatusCell,
  },
];
