import { ReactComponent as ProductivityByAttorney } from '../../../assets/icons/chart-square.svg';
import { ReactComponent as CaseStatusComparison } from '../../../assets/icons/check.svg';
import { ReactComponent as InactiveClient } from '../../../assets/icons/clipboard-close.svg';
import { ReactComponent as EvictionFiled } from '../../../assets/icons/document-normal.svg';
import { ReactComponent as AttorneyPerformance } from '../../../assets/icons/money-send.svg';
import { ReactComponent as ClientBilling } from '../../../assets/icons/money-time.svg';
import { ReactComponent as CaseComparisonByEmployee } from '../../../assets/icons/personalcard.svg';
import { ReactComponent as CourtSchedule } from '../../../assets/icons/video-time.svg';
import { ReactComponent as StatusUp } from '../../../assets/icons/status-up.svg';
import { ReactComponent as Chart } from '../../../assets/icons/chart.svg';
import { ReactComponent as PresentationChart } from '../../../assets/icons/presention-chart.svg';

export const reportsConfig = {
  name: 'Reports',

  emptyLabel: `You don’t have any reports yet`,
  emptyHint: `Every report  you  create will appear here. 
To get started select report  below:`,

  dropdownLabel: 'Select Report',
  dropdownPlaceholder: '-',
  dropdownOptions: {
    db: [
      {
        value: 'casestatuscomparison',
        label: 'Case Status Comparison',
        info: 'Compare number of cases in particular status(es) by state and time period(s)',
        icon: CaseStatusComparison,
      },
      {
        value: 'casecomparisonbyemployee',
        label: 'Case Comparison by Employee',
        info: 'Employee productivity ranking by amount of processed evictions/drafted notices',
        icon: CaseComparisonByEmployee,
      },
      {
        value: 'productivityByAttorney',
        label: 'Productivity by Attorney',
        info: 'Ranking of selected attorney(s) productivity by time and expense',
        icon: ProductivityByAttorney,
      },
      {
        value: 'courtSchedule',
        label: 'Court Schedule',
        info: 'View schedule of court hearings by state and county',
        icon: CourtSchedule,
      },
      {
        value: 'evictionFiledDaily',
        label: 'Eviction Filed Daily ',
        info: 'Control the process of eviction filed cases with your daily report',
        icon: EvictionFiled,
      },
      {
        value: 'attorneyPerformance',
        label: 'Attorney Performance',
        info: 'Attorney ranking by billed hours',
        icon: AttorneyPerformance,
      },
      {
        value: 'inactiveClientReport',
        label: 'Inactive Client',
        info: 'Overview of managements and communities that are idle for 30/60/90/more than 90 days',
        icon: InactiveClient,
      },
      {
        value: 'clientBillingReport',
        label: 'Client Billing',
        info: 'Ranking of managements and communities by total billed attorney hours',
        icon: ClientBilling,
      },
      {
        value: 'clientActivityReport',
        label: 'Client Activity',
        info: 'Ranking of managements and communities by requested types of services',
        icon: StatusUp,
      },
      {
        value: 'clientAcquisitionReport',
        label: 'Client Acquisition',
        info: 'Overview of newly added managements and communities',
        icon: Chart,
      },
      {
        value: 'billingAudit',
        label: 'Billing Audit',
        info: 'Overview of billing instances by status',
        icon: PresentationChart,
      },
    ],
    cp: [
      {
        value: 'courtreport',
        label: 'Court Report',
        info: 'Court schedule for all cases concerning properties that you manage',
        icon: CourtSchedule,
      },
      {
        value: 'activityreport',
        label: 'Property Activity',
        info: 'Ranking of properties that you manage by requested types of services',
        icon: ProductivityByAttorney,
      },
      {
        value: 'summaryreport',
        label: 'Case Summary Report',
        info: 'Overview of all ongoing evictions at properties that you manage',
        icon: PresentationChart,
      },
    ],
  },
};
