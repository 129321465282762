export const FeesAndExpensesColumns = [
  {
    Header: 'Name',
    accessor: 'key',
  },
  {
    Header: 'Value',
    accessor: 'value',
  },
];
