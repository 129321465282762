import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import MButton from '../../MUI/Button/MButton';
import { palette } from '../../../theme/default';

const Tile = ({ icon: Icon, label, info, cta = null, ctaText = '', ctaAction }) => {
  return (
    <Box
      boxShadow="0px 3px 32px 0px rgba(106, 114, 142, 0.10)"
      borderRadius={3}
      padding={4}
      display="flex"
      flexDirection="column"
      gap={3}
      width="431px"
      minHeight="306px"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="column" gap={4}>
        <Icon />
        <Box display="flex" flexDirection="column" gap={1.5}>
          <Typography variant="h5" color={palette.statuses.black} data-testid="tile_label">
            {label}
          </Typography>
          <Typography variant="bodyM" color={palette.statuses.black} data-testid="tile_info">
            {info}
          </Typography>
        </Box>
      </Box>
      {cta || (
        <MButton variant="bordered" onClick={ctaAction} size="large" data-testid="tile_cta">
          {ctaText}
        </MButton>
      )}
    </Box>
  );
};

Tile.propTypes = {
  icon: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  ctaAction: PropTypes.func.isRequired,
};

export default Tile;
