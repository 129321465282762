import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Users from '../../../components/pages/Admin/UsersFlow';
import Roles from '../../../components/pages/Admin/RolesFlow';
import Admin from '../../../components/pages/Admin';
import DocumentsTemplates from '../../../components/pages/Admin/DocumentsFlow/DocumentsTemplates';
import DocumentTemplateHistory from '../../../components/pages/Admin/DocumentsFlow/DocumentTemplateHistory';
import DocumentsContainer from '../../../components/pages/Admin/DocumentsFlow/DocumentsContainer';
import ClientRequestNotifications from '../../../components/pages/Admin/ClientRequestNotificationsFlow/ClientRequestNotifications';
import LoginEdit from '../../../components/pages/Admin/LoginEdit/LoginEdit';
import FeesAndExpenses from '../../../components/pages/Admin/FeesAndExpenses/FeesAndExpenses';

const DataBaseAdminRoutes = () => (
  <Routes>
    <Route path="/" element={<Admin />}>
      <Route path="users" element={<Users />} />
      <Route path="roles" element={<Roles />} />

      <Route path="documents" element={<DocumentsTemplates />} />

      <Route path="documents/document/*" element={<DocumentsContainer />}>
        <Route path="history" element={<DocumentTemplateHistory />} />
      </Route>

      <Route path="clientrequestnotifications" element={<ClientRequestNotifications />} />
      <Route path="login" element={<LoginEdit />} />

      <Route path="feesexpenses" element={<FeesAndExpenses />} />
    </Route>
  </Routes>
);

export default DataBaseAdminRoutes;
