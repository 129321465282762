/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { apiClient } from '../../../lib/apiClient';

export const getFeesAndExpenses = createAsyncThunk(
  'feesAndExpenses/getFeesAndExpenses',
  async () => {
    const {
      data: { result },
    } = await apiClient.get('/api/fees/cases/expenses');
    return result;
  },
);

export const getFeesAndExpensesOptions = createAsyncThunk(
  'feesAndExpenses/getFeesAndExpensesOptions',
  async () => {
    const {
      data: {
        result: { items },
      },
    } = await apiClient.get('/api/fees/cases/expenses');
    return items.map((item) => ({
      label: item.key,
      value: item.key,
      amount: item.value,
    }));
  },
);

export const updateFeesAndExpenses = createAsyncThunk(
  'feesAndExpenses/updateFeesAndExpenses',
  async (body) => {
    const { data } = await apiClient.put(`/api/fees/cases/expenses`, body);
    return data;
  },
);

export const resetFeesAndExpenses = createAction('feesAndExpenses/resetFeesAndExpenses', () => ({
  type: 'feesAndExpenses/resetFeesAndExpenses',
}));

const initialState = {
  items: [],
  options: [],
  error: null,
};

export const feesAndExpensesSlice = createSlice({
  name: 'feesAndExpenses',
  initialState,
  reducers: {},
  extraReducers: {
    [getFeesAndExpenses.fulfilled]: (state, action) => {
      state.items = action.payload.items;
    },
    [getFeesAndExpensesOptions.fulfilled]: (state, action) => {
      state.options = action.payload;
    },
    [resetFeesAndExpenses]: (state) => {
      state.items = [];
    },
    [getFeesAndExpenses.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [updateFeesAndExpenses.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default feesAndExpensesSlice.reducer;
