export const BILLING_STATUSES = [
  { label: 'Billed', value: 'Billed' },
  { label: 'Unbilled', value: 'Unbilled' },
];

export const BillingAuditFormBoxes = [
  {
    label: 'From Date',
    name: 'startDate',
    width: '226px',
    placeholder: 'mm/dd/yyyy',
    isMandatory: true,
    isDatePicker: true,
    height: '48px',
  },
  {
    label: 'To Date',
    name: 'endDate',
    width: '226px',
    placeholder: 'mm/dd/yyyy',
    isMandatory: true,
    isDatePicker: true,
    height: '48px',
  },
  {
    label: 'Status(es)',
    name: 'status',
    placeholder: '-',
    width: '226px',
    isCheckboxSelect: true,
    isMandatory: false,
  },
];

export const BillingAuditColumns = [
  {
    Header: '#',
    accessor: 'lineNo',
  },
  {
    Header: 'Customer Ref_Full Name',
    accessor: 'customerRefFullName',
  },
  {
    Header: 'Customer Ref_List ID',
    accessor: 'customerRefListId',
  },
  {
    Header: 'Reference #',
    accessor: 'referenceNumber',
    minWidth: 160,
  },
  {
    Header: 'Opposing Party Name',
    accessor: 'opposingParties',
  },
  {
    Header: 'Billing Instance',
    accessor: 'billingInstanceName',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
  },
  {
    Header: 'Date',
    accessor: 'date',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
];
