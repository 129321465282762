import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { isEmpty } from 'ramda';
import Table from '../../../atoms/Table/Table';
import EmptyBlock from '../../../molecules/EmptyBlock';
import {
  getFeesAndExpenses,
  resetFeesAndExpenses,
  updateFeesAndExpenses,
} from '../../../../store/slices/dbAdmin/feesAndExpensesSlice';
import { FeesAndExpensesColumns } from './FeesAndExpenses.constants';
import MButton from '../../../MUI/Button/MButton';
import { palette } from '../../../../theme/default';
import useSafeLocationState from '../../../../hooks/useSafeLocationState';
import EditInputWithLabel from '../../../molecules/EditInputWithLabel';
import notificationUtils from '../../../../utils/notificationUtils';

const FeesAndExpenses = () => {
  useSafeLocationState('Admin', 'Fees & Expenses');
  const dispatch = useDispatch();

  const { items } = useSelector((state) => state.feesAndExpenses);
  const [isLoader, setIsLoader] = useState(false);
  const [values, setValues] = useState({});

  const resetValues = () => {
    setValues({});
  };

  const setValue = (key) => (e) => {
    setValues((prev) => ({
      ...prev,
      [key]: e.target.value,
    }));
  };

  useEffect(() => {
    setIsLoader(true);
    dispatch(getFeesAndExpenses()).finally(() => setIsLoader(false));

    return () => {
      dispatch(resetFeesAndExpenses());
    };
  }, []);

  const updateFees = async () => {
    setIsLoader(true);

    const fees = Object.keys(values).map((key) => ({
      key,
      value: parseFloat(values[key]),
    }));

    try {
      await dispatch(updateFeesAndExpenses({ items: fees })).unwrap();
      await dispatch(getFeesAndExpenses()).unwrap();
      setValues({});
    } catch (e) {
      notificationUtils.error('Unexpected error during updating fees and expenses');
    }
    setIsLoader(false);
  };

  const rowsAdapter = items?.map((item) => ({
    ...item,
    value: (
      <EditInputWithLabel
        type="float"
        name={item.key}
        value={values[item.key] || item.value}
        onChange={setValue(item.key)}
      />
    ),
  }));

  return (
    <Stack direction="column">
      <Typography variant="h3" margin="0 32px 32px">
        Fees And Expenses
      </Typography>
      <Stack display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Box width="1140px" marginX="auto" marginBottom={25}>
          {isLoader && (
            <Box
              position="absolute"
              top="50vh"
              left="50%"
              zIndex={10}
              transform="translate(-50%, -50%)"
            >
              <CircularProgress />
            </Box>
          )}
          {!isLoader && !!rowsAdapter?.length && (
            <Stack spacing={3}>
              <Table columns={FeesAndExpensesColumns} rows={rowsAdapter} loading={false} />
            </Stack>
          )}
          {!isLoader && !rowsAdapter?.length && (
            <Box display="flex" justifyContent="center">
              <EmptyBlock title="No records" />
            </Box>
          )}
        </Box>
        <Box
          position="fixed"
          bottom={0}
          zIndex={10}
          width="100vw"
          p={4}
          boxShadow={`0px -10px 32px ${palette.shadow.boxAccent}`}
          bgcolor="#FFF"
        >
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <MButton
              variant="secondary"
              size="large"
              disabled={isEmpty(values)}
              onClick={resetValues}
              sx={{ width: '144px' }}
            >
              Cancel
            </MButton>
            <MButton
              type="submit"
              size="large"
              disabled={isEmpty(values)}
              onClick={updateFees}
              sx={{ width: '144px' }}
            >
              Update
            </MButton>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
};

export default FeesAndExpenses;
