import React, { useMemo, memo, useContext } from 'react';
import { AppBar, Stack, Toolbar } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Navigation from '../Navigation/Navigation';
// import Notification from '../../atoms/Notification';
// import Search from '../../molecules/Search';
import LoginWidget from '../../molecules/LoginWidget';
import logo from '../../../assets/menu-logo.png';
import {
  getClientPortalNavbar,
  getDatabaseNavbar,
  getLandingPagesByRoles,
} from './TopNavBar.constants';
import AuthContext from '../../../context/AuthContext';
import { getDefaultPage, rolesDB } from '../../../utils/roleHelpers';
import { useBaseUrl } from '../../../hooks/useBaseUrl';

const TopNavBar = memo(() => {
  // const [searchClicked, setSearchClicked] = useState(false);
  const { userInfo } = useContext(AuthContext);
  const base = useBaseUrl();
  const { profile } = useSelector((state) => state.userProfile);
  const { isClientPortalUser = true, roles = [] } = userInfo ?? {};

  let excludedPages = [];
  if (!userInfo.stateCodes?.includes('AZ')) {
    excludedPages = ['Legal Forms'];
  }

  const availablePagesByRoles = getLandingPagesByRoles(
    roles,
    isClientPortalUser,
    base,
    excludedPages,
  );

  const isCPUserFromDB =
    isClientPortalUser &&
    roles.some((role) => {
      return Object.values(rolesDB).includes(role);
    });

  const chosenPage = availablePagesByRoles.find((item) => item.label === profile?.landingPage);

  const defaultPage = isCPUserFromDB ? `/${base}/cases/casesearch` : chosenPage?.href;

  const navigationBar = useMemo(() => {
    const barItems = isClientPortalUser
      ? getClientPortalNavbar(base, excludedPages)
      : getDatabaseNavbar();

    const filteredSub = barItems.reduce((acc, item) => {
      if (
        (Array.isArray(item.whitelist) &&
          !!roles.filter((role) => item.whitelist.includes(role)).length) ||
        !Array.isArray(item.whitelist)
      ) {
        const filteredSubNavByRole = item.dropdownNavigationConfig?.filter((subNav) => {
          return (
            (Array.isArray(subNav.whitelist) &&
              !!roles.filter((role) => subNav.whitelist.includes(role)).length) ||
            !Array.isArray(subNav.whitelist)
          );
        });
        acc.push({ ...item, dropdownNavigationConfig: filteredSubNavByRole });
      }
      return acc;
    }, []);

    return filteredSub;
  }, [isClientPortalUser, roles]);

  return (
    <AppBar
      elevation={0}
      position="relative"
      sx={{
        backgroundColor: 'common.white',
        borderBottom: 1,
        borderColor: 'additional.lines',
        display: 'flex',
        justifyContent: 'center',
        height: '80px',
      }}
      data-testid="top_navbar_wrapper"
    >
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Stack spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
          <Link
            to={getDefaultPage(undefined, defaultPage)}
            data-testid="top_navbar_default_page_link"
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <img
              src={logo}
              width="87px"
              alt="Scott & Clark logo"
              cursor="pointer"
              data-testid="top_navbar_logo"
            />
          </Link>
          <Navigation navData={navigationBar} />
        </Stack>
        <Stack spacing={2} direction="row">
          {/*
           *<Search clicked={searchClicked} setClicked={setSearchClicked} />
           *<Notification />
           */}
          <LoginWidget />
        </Stack>
      </Toolbar>
    </AppBar>
  );
});

export default TopNavBar;
